@import "src/scss/variables";

.home {
  display: flex;
  flex-direction: column;

  &-container {
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    gap: 72px;
  }
  &-categories {
    --statu-gutter-x: 16px;
    --statu-gutter-y: 16px;

    &__item {
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 12px;
      width: 100%;
      background: $color-white;
      border: 1px solid rgba(var(--color-gray-light-rgb), .2);
      border-radius: 4px;

      i {
        color: $primary-orange;
        font-size: 12px;
      }
      span {
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        color: $color-text;
      }
    }
  }
  &-stories {
    border-top: 1px solid rgba(var(--color-gray-light-rgb), .2);
    padding: 12px 16px;
    display: none;
    align-items: center;
    gap: 12px;
    overflow: auto;
    white-space: nowrap;

    &__item {
      display: flex;
      flex-direction: column;
      text-align: center;
      gap: 6px;
      max-width: 48px;

      &-image {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 48px;
        width: 48px;
        border-radius: 50%;
        background: $secondary-gray;
        overflow: hidden;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center center;
        }
      }
      &-title {
        font-weight: 700;
        font-size: 10px;
        line-height: 14px;
        text-align: center;
        word-break: break-all;
        color: $color-black;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      &.active &-image {
        border: 2px solid $primary-orange;
      }
    }
  }
}
@include media-breakpoint-down(lg) {
  .home {
    &-container {
      padding-top: 14px;
      padding-bottom: 14px;
      gap: 48px;
    }
    &-stories {
      display: flex;
    }
  }
}
@include media-breakpoint-down(md) {
  .home {
    &-container {
      gap: 36px;
    }
    &-categories {
    }
  }
}