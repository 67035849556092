/* Prefix
 * -------------------------------------------------- */
/* Theme Colors
 * -------------------------------------------------- */
/* Breakpoints
 * -------------------------------------------------- */
/* Spacing
 * -------------------------------------------------- */
/* Others
 * -------------------------------------------------- */
/* Functions And Mixins
 * -------------------------------------------------- */
.home {
  display: flex;
  flex-direction: column;
}
.home-container {
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  gap: 72px;
}
.home-categories {
  --statu-gutter-x: 16px;
  --statu-gutter-y: 16px;
}
.home-categories__item {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 12px;
  width: 100%;
  background: var(--color-white);
  border: 1px solid rgba(var(--color-gray-light-rgb), 0.2);
  border-radius: 4px;
}
.home-categories__item i {
  color: var(--color-orange);
  font-size: 12px;
}
.home-categories__item span {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: var(--color-gray);
}
.home-stories {
  border-top: 1px solid rgba(var(--color-gray-light-rgb), 0.2);
  padding: 12px 16px;
  display: none;
  align-items: center;
  gap: 12px;
  overflow: auto;
  white-space: nowrap;
}
.home-stories__item {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 6px;
  max-width: 48px;
}
.home-stories__item-image {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background: var(--color-gray-light);
  overflow: hidden;
}
.home-stories__item-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center center;
}
.home-stories__item-title {
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  word-break: break-all;
  color: var(--color-black);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.home-stories__item.active .home-stories__item-image {
  border: 2px solid var(--color-orange);
}

@media (max-width: 991.98px) {
  .home-container {
    padding-top: 14px;
    padding-bottom: 14px;
    gap: 48px;
  }
  .home-stories {
    display: flex;
  }
}
@media (max-width: 767.98px) {
  .home-container {
    gap: 36px;
  }
}